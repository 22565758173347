import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Christmas Roundup 2022",
  "date": "2022-12-30",
  "author": "Andy B",
  "featuredImage": "pi-tree.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We’ve now completed our first full term since CoderDojo restarted at the Bishops Cleeve Library.  It’s been wonderful to have the club up and running again, and to have had so many people join us to learn about coding.  A big thank you to everyone who joined us throughout the autumn!`}</p>
    <h2>{`The term in numbers`}</h2>
    <ul>
      <li parentName="ul">{`113 spaces were booked over the 8 club sessions`}</li>
      <li parentName="ul">{`70% of attendees enjoyed it so much that they came back for more`}</li>
      <li parentName="ul">{`We had an almost even gender split, with girls making up 45% of the attendees`}</li>
    </ul>
    <h2>{`What we made`}</h2>
    <p>{`Many of the ninjas spent this year working through the `}<a parentName="p" {...{
        "href": "https://projects.raspberrypi.org/en/pathways/scratch-intro"
      }}>{`Introduction to Scratch learning path`}</a>{`.`}</p>
    <p>{`We saw some incredibly creative interpretaions of these projects.  Here a just a few of the things which were made this term:`}</p>
    <h4>{`Funny animations`}</h4>
    <iframe src="https://scratch.mit.edu/projects/736406497/embed" allowtransparency="true" width="485" height="402" frameBorder="0" scrolling="no" allowFullScreen></iframe>
    <p><a parentName="p" {...{
        "href": "https://scratch.mit.edu/projects/736406497"
      }}>{`View the code`}</a></p>
    <h4>{`Lots of crazy eyes`}</h4>
    <iframe src="https://scratch.mit.edu/projects/764448338/embed" allowtransparency="true" width="485" height="402" frameBorder="0" scrolling="no" allowFullScreen></iframe>
    <p><a parentName="p" {...{
        "href": "https://scratch.mit.edu/projects/764448338"
      }}>{`View the code`}</a></p>
    <h4>{`Surprise animations!`}</h4>
    <iframe src="https://scratch.mit.edu/projects/771477314/embed" allowtransparency="true" width="485" height="402" frameBorder="0" scrolling="no" allowFullScreen></iframe>
    <p><a parentName="p" {...{
        "href": "https://scratch.mit.edu/projects/771477314/"
      }}>{`View the code`}</a></p>
    <p>{`You can find more of the projects we've created in `}<a parentName="p" {...{
        "href": "https://scratch.mit.edu/studios/32126251"
      }}>{`our Scratch studio`}</a>{`.  If you'd like to share anything which was done this term, feel free to add to the collection!`}</p>
    <h2>{`Treasure Hunt`}</h2>
    <p>{`As well as Scratch, we also had some ninja's trying out programming the tiny `}<a parentName="p" {...{
        "href": "https://microbit.org/"
      }}>{`Micro:bit computer`}</a>{`.  `}</p>
    <p>{`We finished off the year with a Christmas treasure hunt using the Micro:bit's radios - with one acting as a "finder" and other's hidden aronud the library broadcasting a signal so they can be found.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a3b61bf1f2caaaaec5c23c00e2b67a8/c08c5/microbits.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.271186440677965%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAVWFqB6JBl//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIQETJB/9oACAEBAAEFAhSzXI7o/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BZ//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGNf//EABcQAAMBAAAAAAAAAAAAAAAAAAAQETH/2gAIAQEABj8CJkVf/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEhMUFREWGR/9oACAEBAAE/IU2u3ohI5KONGWQz9lD/2gAMAwEAAgADAAAAECjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/EMo//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxAP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUExUf/aAAgBAQABPxBEUH0tBaCQac+QsHggC4spS8gMroiuP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "microbits",
            "title": "microbits",
            "src": "/static/4a3b61bf1f2caaaaec5c23c00e2b67a8/c08c5/microbits.jpg",
            "srcSet": ["/static/4a3b61bf1f2caaaaec5c23c00e2b67a8/1c91a/microbits.jpg 295w", "/static/4a3b61bf1f2caaaaec5c23c00e2b67a8/1c72d/microbits.jpg 590w", "/static/4a3b61bf1f2caaaaec5c23c00e2b67a8/c08c5/microbits.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you'd like to see the code behind the treasure hunt, you can view it on `}<a parentName="p" {...{
        "href": "https://github.com/Bishops-Cleeve-CoderDojo/christmas-microbit-trail"
      }}>{`our GitHub page`}</a>{`.`}</p>
    <p>{`The library has kindly supplied us with another batch of micro bits and hardware kits, so there will be much more of this sort of thing in the new year!  Remember you can also borrow a micro:bit from the library - look for the kits in the children's section.`}</p>
    <h2>{`Thanks, and happy new year!`}</h2>
    <p>{`Finally, none of this would be possible without our great group of volunteers, who generously give up their time and share their knowledge, and also the Bishops Cleeve Library who put up with our craziness every two weeks!  Huge thanks to them all!`}</p>
    <p>{`Wishing you all a very happy 2023, full of lots of coding!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      